import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CallIcon from '@material-ui/icons/Call';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage, intlShape, defineMessages } from 'react-intl';

const greenA700 = '#009a3c';

const localeMessages = defineMessages({
});

function IsBusyFromCallState(callStatus) {
  switch(callStatus) {
    case 'accepted':
    case 'recovering':
    case 'calling':
    case 'established':
      return true;
    default:
      return false;
  }
}

class LiveView extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  getCallState(callStatus) {
    if (IsBusyFromCallState(callStatus)) {
      return (<FormattedMessage
        id='Supporter.LiveView.Talking'
        defaultMessage="Im Gespräch"
      />);
    }
    else {
      return (<FormattedMessage
        id='Supporter.LiveView.Idle'
        defaultMessage="Frei"
      />);
    }
  }

  render() {
    const users = this.props.groupUsers.filter(u => u.id !== this.props.user.id).map(user => {
      return (
      <Card key={user.id}>
        <CardHeader
          style={{borderTop: '0.65em solid ' + (user.online ? greenA700 : '#bdbdbd')}}
          avatar={
            <Avatar style={{backgroundColor: user.online ? greenA700 : '#bdbdbd'}}>
              <AccountCircle style={{width: '100%', height: '100%'}}/>
            </Avatar>
          }
          title={user.name}
          subheader={user.online ? 'Online' : 'Offline'}
        />
        <CardContent>
          <Typography color="textSecondary">
            {user.online && <strong>
              <FormattedMessage
                id='Supporter.LiveView.Status'
                defaultMessage="Status: "
              />{this.getCallState(user.callStatus)}
            </strong>}
          </Typography>
        </CardContent>
        <CardActions>
          <IconButton size="small" onClick={(e) => this.props.preview(e.screenX, e.screenY, this.props.getInviteLink(null, null, null, user) + '&n=' + this.props.user.name)}>
            <CallIcon />
          </IconButton>
        </CardActions>
      </Card>);
    })
    return (
      <div>
        <div className="">
          <h2>
            <FormattedMessage
              id='Supporter.LiveView.Title'
              defaultMessage="LiveView"
            />
          </h2>
          <div style={{display:'grid', gridTemplateColumns: 'repeat( auto-fit, minmax(15em, 1fr)', gridGap: '1em'}}>
            {users}
          </div>
        </div>
      </div>
    )
  }
};

export const getBusyStateFromCallState = IsBusyFromCallState;
export default LiveView;